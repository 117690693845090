import React, { useLayoutEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Card, CardBody } from 'reactstrap'
import LogoHeadr from '../components/LogoHeadr'
import Scheduler from "./App";
import { G_SERVER_URL } from "../utils/global";
import ComponentSpinner from "../components/ComponentSpinner";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const scheduleArr = [
  { name: 'Sunday', status: false, day: 0, start_time: '08:00', end_time: '18:00' },
  { name: 'Monday', status: true, day: 1, start_time: '08:00', end_time: '18:00' },
  { name: 'Tuesday', status: true, day: 2, start_time: '08:00', end_time: '18:00' },
  { name: 'Wednesday', status: false, day: 3, start_time: '08:00', end_time: '18:00' },
  { name: 'Thursday', status: true, day: 4, start_time: '08:00', end_time: '18:00' },
  { name: 'Friday', status: true, day: 5, start_time: '08:00', end_time: '18:00' },
  { name: 'Saturday', status: false, day: 6, start_time: '08:00', end_time: '18:00' },
];

const BookingPage = () => {

  const [scheduleArray, setScheduleArray] = useState(JSON.parse(localStorage.getItem('scheduledays'))||scheduleArr); // New state for email
  const [loading, setLoading] = useState(true)

  const getData = async (urlId) => {
    const url=G_SERVER_URL + urlId;
    const res = await fetch(url)
    if (res.status !== 200) {
         console.log("Error: non-200 status", res.status)
         return
    }
    const ures = await res.json()
    if (ures.error !== null) {
         return
    }
    const userData = ures.user
    return userData
  }


  const getUserData = async () => {
    try {
      setLoading(true)
      const url_id= localStorage.getItem('url_id');
      const userData = await getData(url_id)
      if (userData) {
        setScheduleArray(JSON.parse(userData.days))
      }
    } catch (error) {
        console.log('errrr', error)
    } finally { setLoading(false) }
  }

  useLayoutEffect(() => {
    getUserData()
  }, [])

  if (loading) return <ComponentSpinner />
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <div id="geeks" className="center">
    <div className="v_card"  >
      <Card className='mb-0'>
        <CardBody>
          <LogoHeadr />
      <Container className="mb-5" fluid="xxl">
        <Row>
            <Scheduler scheduleArray={scheduleArray}/>
        </Row>
      </Container>
      </CardBody>
        </Card>
      </div>
    </div>
    </LocalizationProvider>
  );
};

export default BookingPage;