import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import ComponentSpinner from '../components/ComponentSpinner';
import { G_SERVER_URL, G_SERVER_URL_IMPRESSIONS } from '../utils/global';
import './styles.css';
import GoogleMapReact from 'google-map-react'
import { useHistory,useParams } from 'react-router-dom'
import FlagComponent from '../components/FlagComp';
import { isAndroid, isIOS } from 'react-device-detect'


const followData={
     "linkedIn":{
          title:"follow",
          image:"iconlkd.png"
     },//LinkedIn
     "twitter":{
          title:"follow",
          image:"xlogo.webp"
     },//X
     "webLink":{
          title:"",
          image:"logoWeb.png"
     },//Website
     "youtube":{
          title:"subscribe",
          image:"youtube.png"
     },//youtube
     "emailShare":{
          title:"",
          image:"iconmail.png"
     },//Email
     "phone":{
          title:"",
          image:"phone.png"
     },//phone
     "fbLink":{
          title:"follow",
          image:"logo_instagram.png"
     },//Instagram
     "instaLink":{
          title:"follow",
          image:"images.png"
     },//TikTok
     "tinder":{
          title:"follow",
          image:"tinder.webp"
     },//Tinder
     "dribbble":{
          title:"subscribe",
          image:"dribble.png"
     },//Dribbble
     "behance":{
          title:"subscribe",
          image:"behance.jpeg"
     },//Behance
     "twitch":{
          title:"follow",
          image:"twitch.png"
     },//Twitch
     "reddit":{
          title:"follow",
          image:"reddit.png"
     },//Reddit
     "buyMeACoffee":{
          title:"follow",
          image:"buyMeACoffee.png"
     },//BuyMeACoffee
     "layers":{
          title:"follow",
          image:"layers.png"
     },//Layers
     "patreon":{
          title:"follow",
          image:"patreon.png"
     },//Patreon
     "kofi":{
          title:"follow",
          image:"kofi.png"
     },//Kofi
     "spotify":{
          title:"follow",
          image:"spotify.png"
     },//Spotify
     "podcast":{
          title:"follow",
          image:"podcast.jpeg"
     },//Podcast
     "gitHub":{
          title:"follow",
          image:"gitHub.png"
     },//GitHub
     "facebook":{
          title:"follow",
          image:"facebook.webp"
     },//Facebook
     "threads":{
          title:"follow",
          image:"threads.webp"
     },//Threads
}

const androidTrimLength = 37;
const iosTrimLength = 40;


const useWindowWidth = () => {
     const [width, setWidth] = useState(window.innerWidth);
   
     useEffect(() => {
       const handleResize = () => setWidth(window.innerWidth);
   
       window.addEventListener('resize', handleResize);
   
       // Cleanup on component unmount
       return () => {
         window.removeEventListener('resize', handleResize);
       };
     }, []);
   
     return width;
   };

export default function Home() {
     const width = useWindowWidth();
     const [userData, setUserData] = useState(null)
     const [loading, setLoading] = useState(true)
     const searchParams=useParams()

     function GetURLParameter(urlId) {
          const sPageURL = window.location.href;
          const queryStringIndex = sPageURL.indexOf('?');
          if (queryStringIndex !== -1) {
               const queryString = sPageURL.substring(queryStringIndex + 1);
               const queryParams = queryString.split('&');
               for (const param of queryParams) {
                    const keyValue = param.split('=');
                    if (keyValue[0] === urlId) {
                         const code = keyValue[1];
                         return code;
                    }
               }
          }
     }

     const getData = async (url_id,share,invite,profile) => {
          let url=G_SERVER_URL + url_id;
          if (share) {
               url=url+"&share="+share;
          }
          if (invite) {
               url=url+"&invite="+invite;
          }
          if (profile) {
               url=url+"&profile="+profile;
          }
          const res = await fetch(url)
          if (res.status !== 200) {
               console.log("Error: non-200 status", res.status)
               return
          }
          const ures = await res.json()
          if (ures.error !== null) {
               alert("Error: " + ures.error)
               return
          }
          const userData = ures.user
          return userData
     }


     const getUserData = async () => {
          if (searchParams?.name) {
               const searchUserName=searchParams?.name
               localStorage.setItem('profile', searchUserName);
               try {
                    setLoading(true)
                    const userData = await getData("100","","",searchUserName)
                    if (userData.shareToken) {
                         localStorage.setItem('url_id', userData.shareToken);
                    }
                    localStorage.setItem('scheduledays', userData.days);
                    setUserData(userData)
                    if (userData?.postsData.id) {
                         localStorage.setItem('postId', userData?.postsData.id);
                         addImpression(userData?.postsData.id)
                    }
               } catch (error) {
                    console.log('errrr', error)
               } finally { setLoading(false) }
          }else{
               let url_id = GetURLParameter('auth')
               let share = GetURLParameter('share')
               let invite = GetURLParameter('invite')
               if (share) {
                    localStorage.setItem('share', share);
               }
               if (invite) {
                    localStorage.setItem('invite', invite);
               }
               if (url_id) {
                    localStorage.setItem('url_id', url_id);
                    try {
                         setLoading(true)
                         const userData = await getData(url_id,share,invite)
                         if (userData.shareToken) {
                              localStorage.setItem('url_id', userData.shareToken);
                         }
                         localStorage.setItem('scheduledays', userData.days);
                         setUserData(userData)
                         if (userData?.postsData.id) {
                              localStorage.setItem('postId', userData?.postsData.id);
                              addImpression(userData?.postsData.id)
                         }
                    } catch (error) {
                         console.log('errrr', error)
                    } finally { setLoading(false) }
               }
          }
     }

     useLayoutEffect(() => {
          getUserData()
     }, [])


     const addImpression = async (url_id) => {
          const storedIdentifier = await localStorage.getItem('uniqueIdentifier');
          if (storedIdentifier) return;

          try {
               const res = await fetch(G_SERVER_URL_IMPRESSIONS + url_id)
               if (res.status !== 200) {
                    console.log("Error: non-200 status", res.status)
                    return
               }
               await res.json()
               await localStorage.setItem('uniqueIdentifier', "addimpressions");
          } catch (error) {
               console.log(error)
          }
     }


     const email = userData?.postsData?.email;
     const subject = '';
     const body = '';

     const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
     const phoneTo = `tel:${userData?.postsData?.phone}`
     const history = useHistory();

     const onClickContacts = () => {
          localStorage.setItem('id', String(userData?.postsData?.id));
          localStorage.setItem('user', String(userData?.firstName?.value + " " + userData?.lastName?.value))
          localStorage.setItem('message', "send")
          history.push('/social-login')
     }

     const onClickBookings = () => {
          localStorage.setItem('user', String(userData?.firstName?.value + " " + userData?.lastName?.value))
          localStorage.setItem('message', "bookings")
          history.push('/social-login')
     }

     const requestPermissions = (arg) => {
          localStorage.setItem('pdf', arg);
          localStorage.setItem('user', String(userData?.firstName?.value + " " + userData?.lastName?.value))
          localStorage.setItem('message', "permissions")
          history.push('/social-login')
     }

     const shareData = async () => {
          if (navigator.share) {
               const url_id = localStorage.getItem('url_id');
               const share = localStorage.getItem('share');

               let url='https://profile.beyouid.com/?auth=' + url_id
               if (share) {
                    url=url+"&share="+share
               }

               await navigator.share({
                    title: "BeYouID post",
                    text: `${userData?.postsData?.title}${`\n`}${userData?.postsData?.description}${`\n`} Here look onto my BeYouID profile ${`\n`}${url}`,
               })
                    .then(() => console.log('successful share'))
                    .catch((error) => console.log('error in sharing', error));
          } else {
               const url_id = localStorage.getItem('url_id');
               const share = localStorage.getItem('share');

               let url='https://profile.beyouid.com/?auth=' + url_id
               if (share) {
                    url=url+"&share="+share
               }
               const shareText = `${userData?.postsData?.title}\n${userData?.postsData?.description}\nHere look onto my BeYouID profile\n${url}`;

               const textArea = document.createElement('textarea');
               textArea.value = shareText;
               document.body.appendChild(textArea);
               textArea.select();
               document.execCommand('copy');
               document.body.removeChild(textArea);
               alert('Text copied to clipboard')
               console.log('Text copied to clipboard');
          }
     }

     const mapOptions = {
          fullscreenControl: false,
          zoomControl: false
     };

     const memoizedData=useMemo(()=>{
          if ( userData == null ) return []
          const mergedData = Object.keys(followData)
          .filter((key) => key in userData) // Check if the key exists in both objects
          .map((key) =>{
          if(userData[key]!=='') return ({
            key,
            value1: followData[key],
            link: userData[key],
          })}).filter((item) => item!==undefined)

          return mergedData
     },[userData])

     if (loading) return <ComponentSpinner />


     return (
          <div style={{ width: '100%' }}>
               <section >
                    <video controls style={{ width: '100%', height: '300px', background: 'black', }} src={userData?.profileVideo}></video>
               </section>
               <section class="container_back" style={{ padding: '20px' }}>
                    <div class="container" >
                         <div class="row-between">
                              <div>
                                   <div className="grey_bold_18" style={{ textTransform: 'uppercase' }}>{userData?.CurrentLocation}</div>
                                   <h3 className="heading">{userData?.username}</h3>
                              </div>
                              <div style={{width:100}}>
                                <img src={userData?.profilepic} className='profile_image' style={{ background: 'grey' }} />
                              </div>
                         </div>
                         {userData?.days &&
                           <div onClick={() => onClickBookings()} className='book'>
                              <img src={'images/booking.png'} className='appIcons' />
                              <h3 className="bookingText">Book an Appointment</h3>
                           </div>
                         }
                    </div>
               </section>

               <section className='p-3 container_back'>
                    <div className='pt-3 px-1 container'>
                         <h3 className="personal_detail py-5">Personal Details</h3>
                         <div className="title">Full Name</div>
                         <div className="grey_regular_18">{userData?.username}</div>
                         {
                              ((userData?.NCRIM_Show_REPORT_VERIFIED ||
                                   userData?.EDU_Show_REPORT_VERIFIED ||
                                   userData?.MVR_Show_REPORT_VERIFIED) &&
                                   userData.firstName &&
                                   userData.lastName &&
                                   userData.nameVerified) ?
                                   <div className='pb-4 flex'>
                                        <div className='pr-1 bold_18' style={{ color: "#9C5EFF" }}>Verified by: Accurate</div>
                                        <img src="images/beyoudId_logo.png" width="30px" height="30px" />
                                   </div> : null
                         }
                         {/* <!-- Age --> */}
                         <div className="title">Age</div>
                         <div className="grey_regular_18">{userData?.age} years old</div>
                         {
                              ((userData?.MVR_Show_REPORT_VERIFIED ||
                                   userData?.NCRIM_Show_REPORT_VERIFIED ||
                                   userData?.EDU_Show_REPORT_VERIFIED) &&
                                   userData.birthVerified) ?
                                   <div className='pb-2 flex'>
                                        <div className='pr-1 bold_18' style={{ color: "#9C5EFF" }}>Verified by: Accurate</div>
                                        <img src="images/beyoudId_logo.png" width="30" height="30" />
                                   </div> : null
                         }

                         {/* <!-- Education --> */}
                         <div className="title">Education</div>
                         <div className="grey_regular_18">{userData?.Education}</div>
                         {
                              (userData?.EDUVerified && userData?.EDU_Show_REPORT_VERIFIED) ?
                                   <div className='pb-2 flex'>
                                        <div className='pr-1 bold_18' style={{ color: "#9C5EFF" }}>Verified by: Accurate</div>
                                        <img src="images/beyoudId_logo.png" width="30" height="30" />
                                   </div> : null
                         }

                         {/* <!-- Profession --> */}
                         <div className="title">Profession</div>
                         <div className='pb-4 flex' >
                              {/* {
                                   userData.testAccount ?
                                        <div className='grey_regular_18 pr-1'>{userData?.Profession}</div> : */}
                                        <div className='grey_regular_18 pr-1'>{userData?.Profession + ' at ' + userData?.Company}</div>
                              {/* } */}
                              {
                                   userData?.EMPVerified ?
                                        <img src="images/beyoudId_logo.png" style={{ width: '30px', height: '30px' }} /> : null
                              }
                         </div>

                    </div>
               </section>
               {userData?.CurrentLocationVerified ?
               <section className="p-3 container_back">
                    <div className="container px-0">
                         <div className="py-4">
                              <div className="title">Current Location</div>
                              <div className="grey_regular_18">{userData?.CurrentLocation}</div>
                              {
                                   userData?.CurrentLocationVerified ?
                                        <div className="flex">
                                             <div className="bold_18 pr-1" style={{ color: "#9C5EFF" }}>Last Verified {userData?.CurrentLocationVerifiedDate} </div>
                                             <img src="images/beyoudId_logo.png" width="30" height="30" />
                                        </div> : null
                              }
                         </div>
                         {
                              userData?.CurrentLocationVerified ?
                                   <div className="card mapshow" style={{ height: '250px', borderRadius: '30px', marginBottom: '10px', overflow: 'hidden' }}>
                                        <GoogleMapReact
                                             bootstrapURLKeys={{ key: 'AIzaSyA24yQlWCheimegxk33brWgOvsOkZBvsyw' }}
                                             defaultCenter={{ lat: userData?.latitude, lng: userData?.longitude }}
                                             defaultZoom={15}
                                             options={mapOptions}
                                             zoomControl={false}
                                             disableDoubleClickZoom={true}
                                             draggable={false}>
                                             <img src='images/mapmarker.png' width={'50'} height={'50'} />
                                        </GoogleMapReact>
                                   </div> :
                                   <div>
                                        <div className="card mapshow" style={{ height: '250px', borderRadius: '30px', marginBottom: '10px', overflow: 'hidden' }}>
                                             <img src='images/map-placeholder.png' style={{ height: '250px', width: '100%' }} />
                                             <img src="images/locicon.png" className="location_m_icon" />
                                        </div>
                                   </div>
                         }
                    </div>
               </section>:null}

               <section className="p-3 container_back">
                    <div className="container pt-3 px-1">
                         <div className="heading">Background Check</div>
                         <div className="purple_bold_18">Verified by Accurate</div>
                         <a className="accurate_link" href="https://www.accurate.com" target="_blank">www.accurate.com</a>

                         <div className="py-4 grey_regular_18">
                              BeYouID partners with Accurate Background Check LLC to provide safe and secure background checks.
                         </div>
                         {
                              userData?.MVRVerified && userData?.MVR_Show_REPORT_VERIFIED ?
                                   <div >
                                        <div className="title">Motor Vehicle Records</div>
                                        <div className="grey_regular_18">Active Drivers License, Name & Birthdate</div>
                                        <div class="bg_check_verification_mvr purple_bold_18">Verified by Accurate</div>
                                        <div class="pb-4 grey_regular_18">{userData.MVRVerifiedDate}</div>
                                   </div> : null
                         }
                         {/* {
                              userData.testAccount ?
                                   <div >
                                        <div className="title">Education History</div>
                                        <div class="bg_check_verification_mvr purple_bold_18">Verified by Accurate</div>
                                        <div class="pb-4 grey_regular_18">{userData.EDUVerifiedDate}</div>
                                   </div> : null
                         } */}

                         {
                              userData?.NCRIMVerified && userData?.NCRIM_Show_REPORT_VERIFIED ?
                                   <div >
                                        <div className="title">Criminal Background Check</div>
                                        <div className="grey_regular_18">National Criminal Database, Global Watch, County Criminal, National Sex Offender Registry & Federal Criminal
                                             Records</div>
                                        <div className="bg_check_verification_ncrim purple_bold_18">Verified by: Accurate - No records found</div>
                                        <div className="ncrim_verified_date pb-4 grey_regular_18">{userData?.NCRIMVerifiedDate}</div>
                                   </div>
                                   : null}
                         {
                              userData?.VoeVerified && userData?.VOE_REPORT_VERIFIED ?
                                   <div >
                                        <div className="title">Employment History</div>
                                        <div className="bg_check_verification_ncrim purple_bold_18">Verified by: MIMIS LLS</div>
                                        <div className="ncrim_verified_date pb-4 grey_regular_18">{userData?.VoeVerifiedDate}</div>
                                   </div>
                                   : null}
                    </div>
               </section>

               <section className="p-3 container_back">
                    <div className="container">
                         <div className="heading">Verifications</div>

                         {userData?.submittedTestimonials.map((item) => (
                              <div className="v_card mt-4" style={{ padding: '10px' }}>
                                   <div className="card-body text-left">
                                        <div className="flex " style={{ marginBottom: '10px', alignItems: 'center' }}>
                                             {/* {
                                                  userData.testAccount ?
                                                       <img className='profile_image' style={{ background: 'grey' }} src={item?.name == 'Sofia Klein' ? "images/Test/m6.jpg" : item?.name == 'Josh Smith' ? "images/Test/m11.jpg" : "images/Test/m2.jpeg"} alt={item?.name} /> : */}
                                                       <img className='profile_image' style={{ background: 'grey' }} src={item?.imageUrl} alt={item?.name} />
                                             {/* } */}
                                             <div style={{ marginLeft: '10px', width: '130px', display: 'inline-block' }}>
                                                  <h5 className='title'>{item?.name}</h5>
                                             </div>

                                             {
                                                  item?.socialType == 'linkdeIn' ?
                                                       <img src="images/iconlkd.png" style={{ width: '50px', height: '50px', marginLeft: '15px' }} /> :
                                                       <img src="images/iconfb.png" style={{ width: '50px', height: '50px', marginLeft: '15px' }} />
                                             }
                                        </div>
                                        {/* {
                                             !userData.testAccount && */}
                                             <p className="grey_regular_16" style={{ overflow: 'hidden', color: '#8D8C92' }}>{item?.profileTextUrl}</p>
                                        {/* } */}
                                        {
                                             item?.videoPresent ?
                                                  <video style={{ width: '100%', height: '250px', background: 'black', borderRadius: '20px' }} src={item?.videoUrl} controls></video> :
                                                  <p>{item?.testimonialText}</p>
                                        }
                                   </div>
                              </div>
                         ))
                         }

                         {
                              userData?.resumePdf ?
                                   <div className="v_card">
                                        <div className="text-center" style={{ marginTop: '30px' }}>
                                             <div style={{ paddingTop: '15px', justifyContent: 'center', display: 'flex' }}>
                                                  <p className="verification_title2">RESUME</p>
                                             </div>
                                             {
                                                  userData.resumePdf !== 'hide' ?
                                                       <a href={userData.resumePdf} target="_blank">
                                                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                                                 <h1 className="grey_bold_18" style={{
                                                                      cursor: 'pointer',
                                                                      color: '#9C5EFF',
                                                                      paddingTop: '6px',
                                                                      paddingBottom: '6px',
                                                                      width: '100%',
                                                                      alignSelf: 'center',
                                                                      margin: '0px'
                                                                 }}>Click to see the document</h1>
                                                            </div>
                                                       </a> :
                                                       <div>
                                                            <h1 className="grey_bold_18" style={{
                                                                 paddingTop: '6px',
                                                                 fontSize: '15px',
                                                                 width: '100%',
                                                                 alignSelf: 'center',
                                                                 margin: '0px'
                                                            }}>The user has uploaded a document that requires, for the viewer to ask for permission to see it</h1>

                                                            <div onClick={() => requestPermissions('resumePdf')} style={{ justifyContent: 'center', display: 'flex' }}>
                                                                 <h1 className="grey_bold_18" style={{
                                                                      cursor: 'pointer',
                                                                      color: '#9C5EFF',
                                                                      paddingTop: '3px',
                                                                      paddingBottom: '8px',
                                                                      width: '100%',
                                                                      alignSelf: 'center',
                                                                      margin: '0px'
                                                                 }}>Ask for permissions to see</h1>
                                                            </div>
                                                       </div>}


                                        </div>
                                   </div> : null}
                         {userData.highEduPdf ?
                              <div className="v_card">
                                   <div className="text-center" style={{ marginTop: '30px' }}>
                                        <div style={{ paddingTop: '15px', justifyContent: 'center', display: 'flex' }}>
                                             <p className="verification_title2">HIGHEST LEVEL OF EDUCATION</p>
                                        </div>
                                        {
                                             userData.highEduPdf !== 'hide' ?
                                                  <a href={userData.highEduPdf} target="_blank">
                                                       <div style={{ justifyContent: 'center', display: 'flex' }}>
                                                            <h1 className="grey_bold_18" style={{
                                                                 cursor: 'pointer',
                                                                 color: '#9C5EFF',
                                                                 padding: '6px',
                                                                 paddingLeft: '18px',
                                                                 paddingRight: '18px',
                                                                 width: '100%',
                                                                 alignSelf: 'center',
                                                                 margin: '0px'
                                                            }}>Click to see the document</h1>
                                                       </div>
                                                  </a> :
                                                  <div>
                                                       <h1 className="grey_bold_18" style={{
                                                            paddingTop: '6px',
                                                            fontSize: '15px',
                                                            paddingLeft: '18px',
                                                            paddingRight: '18px',
                                                            width: '100%',
                                                            alignSelf: 'center',
                                                            margin: '0px'
                                                       }}>The user has uploaded a document that requires, for the viewer to ask for permission to see it</h1>

                                                       <div onClick={() => requestPermissions("highEduPdf")} style={{ justifyContent: 'center', display: 'flex' }}>
                                                            <h1 className="grey_bold_18" style={{
                                                                 cursor: 'pointer',
                                                                 color: '#9C5EFF',
                                                                 padding: '3px',
                                                                 paddingBottom: '8px',
                                                                 paddingLeft: '18px',
                                                                 paddingRight: '18px',
                                                                 width: '100%',
                                                                 alignSelf: 'center',
                                                                 margin: '0px'
                                                            }}>Ask for permissions to see</h1>
                                                       </div>
                                                  </div>}


                                   </div>
                              </div> : null}

                         {
                              userData.extraEduPdf ?
                                   <div className="v_card">
                                        <div className="text-center" style={{ marginTop: '30px' }}>
                                             <div style={{ paddingTop: '15px', justifyContent: 'center', display: 'flex' }}>
                                                  <p className="verification_title2">EXTRA CREDENTIALS</p>
                                             </div>
                                             {
                                                  userData.extraEduPdf !== 'hide' ?
                                                       <a href={userData.extraEduPdf} target="_blank">
                                                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                                                 <h1 className="grey_bold_18" style={{
                                                                      cursor: 'pointer',
                                                                      color: '#9C5EFF',
                                                                      padding: '6px',
                                                                      paddingLeft: '18px',
                                                                      paddingRight: '18px',
                                                                      width: '100%',
                                                                      alignSelf: 'center',
                                                                      margin: '0px'
                                                                 }}>Click to see the document</h1>
                                                            </div>
                                                       </a> :
                                                       <div>
                                                            <h1 className="grey_bold_18" style={{
                                                                 paddingTop: '6px',
                                                                 fontSize: '15px',
                                                                 paddingLeft: '18px',
                                                                 paddingRight: '18px',
                                                                 width: '100%',
                                                                 alignSelf: 'center',
                                                                 margin: '0px'
                                                            }}>The user has uploaded a document that requires, for the viewer to ask for permission to see it</h1>

                                                            <div onClick={() => requestPermissions("extraEduPdf")} style={{ justifyContent: 'center', display: 'flex' }}>
                                                                 <h1 className="grey_bold_18" style={{
                                                                      cursor: 'pointer',
                                                                      color: '#9C5EFF',
                                                                      padding: '3px',
                                                                      paddingBottom: '8px',
                                                                      paddingLeft: '18px',
                                                                      paddingRight: '18px',
                                                                      width: '100%',
                                                                      alignSelf: 'center',
                                                                      margin: '0px'
                                                                 }}>Ask for permissions to see</h1>
                                                            </div>
                                                       </div>}


                                        </div>
                                   </div> : null}
                         {
                              userData.vaccinePdf ?
                                   <div className="v_card">
                                        <div className="text-center" style={{ marginTop: '30px' }}>
                                             <div style={{ paddingTop: '15px', justifyContent: 'center', display: 'flex' }}>
                                                  <p className="verification_title2">VACCINE CARD</p>
                                             </div>
                                             {
                                                  userData.vaccinePdf !== 'hide' ?
                                                       <a href={userData.vaccinePdf} target="_blank">
                                                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                                                 <h1 className="grey_bold_18" style={{
                                                                      cursor: 'pointer',
                                                                      color: '#9C5EFF',
                                                                      padding: '6px',
                                                                      paddingLeft: '18px',
                                                                      paddingRight: '18px',
                                                                      width: '100%',
                                                                      alignSelf: 'center',
                                                                      margin: '0px'
                                                                 }}>Click to see the document</h1>
                                                            </div>
                                                       </a> :
                                                       <div>
                                                            <h1 className="grey_bold_18" style={{
                                                                 paddingTop: '6px',
                                                                 fontSize: '15px',
                                                                 paddingLeft: '18px',
                                                                 paddingRight: '18px',
                                                                 width: '100%',
                                                                 alignSelf: 'center',
                                                                 margin: '0px'
                                                            }}>The user has uploaded a document that requires, for the viewer to ask for permission to see it</h1>

                                                            <div onClick={() => requestPermissions("vaccinePdf")} style={{ justifyContent: 'center', display: 'flex' }}>
                                                                 <h1 className="grey_bold_18" style={{
                                                                      cursor: 'pointer',
                                                                      color: '#9C5EFF',
                                                                      padding: '3px',
                                                                      paddingBottom: '8px',
                                                                      paddingLeft: '18px',
                                                                      paddingRight: '18px',
                                                                      width: '100%',
                                                                      alignSelf: 'center',
                                                                      margin: '0px'
                                                                 }}>Ask for permissions to see</h1>
                                                            </div>
                                                       </div>}


                                        </div>
                                   </div> : null}

                         {
                             memoizedData.length !==0 ?
                                   <div>

                                        <div className="heading mt-2">Additional information</div>
                                        <div className="v_card" style={{ padding: '15px' }}>
                                             {
                                                  memoizedData.map((item)=>(
                                                       <a href={item.key=='emailShare'?"mailto:"+item.link:item.key=='phone'?"tel:"+item.link: item.link} target="_blank">
                                                       <div className="mb-1" style={{ display: 'flex' }}>
                                                            <img src={'images/'+item.value1.image} className='appIcons' />
                                                            <h5 className='grey_regular_16' style={{ color: '#9C5EFF' }}>{item.link.substring(0, isAndroid ? (width>330?androidTrimLength:androidTrimLength-6) : (width>330?iosTrimLength:iosTrimLength-6))}{item.link.length > (isAndroid ? (width>330?androidTrimLength:androidTrimLength-6) : (width>330?iosTrimLength:iosTrimLength-6)) ? '...' : ""}</h5>
                                                       </div>
                                                  </a>
                                                  ))
                                             }

                                        </div>
                                   </div> : null
                         }

                         {userData?.postsData && userData?.postsData?.id &&
                              <div className="v_card mt-4" style={{ padding: '15px' }}>
                                   <div className="card-body text-left">
                                        <div className="flex" style={{ marginBottom: '10px', alignItems: 'center', justifyContent: 'space-between', }}>
                                             <h5 className='title'>{userData?.postsData?.title}</h5>
                                             <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                  <FlagComponent />
                                                  <h5 className='grey_bold_18' onClick={shareData} style={{ color: '#9C5EFF', cursor: 'pointer' }}>Share</h5>
                                             </div>
                                        </div>
                                        <p>{userData?.postsData?.description}</p>
                                   </div>
                                   <div className='flex' style={{ alignItems: 'center' }}>
                                        <img src='images/mapmarker.png' className='locationIcon' />
                                        <p className='grey_regular_16'>{userData?.postsData?.location}</p>
                                   </div>
                                   <div className='grey_bold_18'>
                                        Contact me
                                        {
                                             userData?.postsData.contactBy.map((item, index) => (
                                                  item !== 'Message through BeYouID' ? (
                                                       <a href={item == 'Email address' ? mailtoLink : phoneTo}>
                                                            <div style={{ color: '#9C5EFF', marginLeft: '10px', cursor: 'pointer' }}>{index + 1}- {item}</div>
                                                       </a>
                                                  )
                                                       :
                                                       <div onClick={() => onClickContacts(item)} style={{ color: '#9C5EFF', marginLeft: '10px', cursor: 'pointer' }} key={index}>{index + 1}- {item}</div>

                                             ))
                                        }
                                   </div>

                              </div>
                         }

                         <p>
                              <div className="v_card p-4" style={{ marginTop: '30px' }}>
                                   <div className="card-body text-left" >
                                        <div className="flex" style={{ alignItems: 'center', paddingBottom: '20px' }}>
                                             <i className="fa fa-envelope" style={{ fontSize: '50px', color: '#9C5EFF', }}></i>
                                             <p className="verification_title">EMAIL</p>
                                        </div>
                                        <div className="row-between">
                                             <span className="grey_medium_20">{userData?.emailVerifiedDate}</span>
                                             <i className="email_verified_icon fa fa-check" style={{ color: '#0091FF' }}></i>
                                        </div>
                                   </div>
                              </div>
                         </p>

                         <section className="mt-3 container_back2">
                              <div className="container">
                                   <div className="heading">Disclaimer: </div>

                                   <div className="py-2 grey_regular_18">
                                   Accurate Background Check Inc., is solely responsible for all data and information displayed on checks that have been verified by Accurate. While the information on those checks have been verified, Mimis LLC., cannot guarantee that there are not mistakes or errors. Checks or Files that have not been verified by any third provider, Mimis LLC., assumes no responsibility or liability for the authenticity, accuracy, validity of any document/information submitted by a user. The user is solely responsible for all data, information, uploaded, and material submitted. Verification of employment is performed by Mimis LLC, while the request has been created by the user and sent to the employer, Mimis LLC., cannot guarantee that there are not mistakes or errors.
                                   </div>
                              </div>
                         </section>
                    </div>
               </section>

          </div>

     )
}
