// ** React Imports
import { useState, useCallback, useEffect } from 'react'
// ** React Imports
import { useHistory } from 'react-router-dom'

// ** Reactstrap Imports
import { Card, CardBody, Input } from 'reactstrap'
import LogoHeadr from '../components/LogoHeadr'

const LeaveMessagefb = () => {
  //  hook
  const history = useHistory()
  // ** State
  const [content, setContent] = useState('')
  const setFormattedContent = useCallback(
    (text) => {
      setContent(text.slice(0, 500))
    },
    [500, setContent]
  )

  const uploadData = async () => {
    if (content.length == 0) return;

    localStorage.setItem('messageContent', content)
    const messa = localStorage.getItem('message')

    if (messa=='bookings') {
      history.push('/booking')
    }else{
      history.push('/SubmitMessagefb')
    }
  }

  return (
    <div id="geeks" className="center">
      <div className="v_card mblcontainer p-10"  >
        <Card className='mb-0'>
          <CardBody>
            <LogoHeadr />
            <div className="title mb-3">
              Leave your message
            </div>
            <div>
              <Input
                name='text'
                value={content}
                type='textarea'
                id='exampleText'
                placeholder='Type...'
                style={{
                  minHeight: '180px',
                  resize: 'none',
                  borderRadius: '5px',
                  width: '100%',
                  borderWidth: '1px',
                  padding: 4
                }}
                onChange={(event) => setFormattedContent(event.target.value)}
                /*eslint-disable */
                invalid={content.length === 0 ? true : false}
              />
              <span className="grey_regular_16 mb-6" style={{ color: 'black', fontFamily: 18 }}>
                {`${content.length}/500 characters`}
              </span>

            </div>
            <button className="btn" onClick={uploadData}>
              <span className="title" style={{ fontSize: 16, color: 'white' }}>Continue</span>
            </button>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default LeaveMessagefb
